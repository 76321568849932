/* Hind */
@font-face {
	font-family: "Hind";
	src: local("HindLight"), url("./assets/fonts/Hind/Hind-Light.ttf") format("truetype");
	font-weight: 300;
}
@font-face {
	font-family: "Hind";
	src: local("HindRegular"), url("./assets/fonts/Hind/Hind-Regular.ttf") format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: "Hind";
	src: local("HindMedium"), url("./assets/fonts/Hind/Hind-Medium.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "Hind";
	src: local("HindSemiBold"), url("./assets/fonts/Hind/Hind-SemiBold.ttf") format("truetype");
	font-weight: 600;
}
@font-face {
	font-family: "Hind";
	src: local("HindBold"), url("./assets/fonts/Hind/Hind-Bold.ttf") format("truetype");
	font-weight: 700;
}

/* Lettown Hills */
@font-face {
	font-family: "Lettown Hills";
	src: local("LettownHillsRegular"),
		url("./assets/fonts/LettownHills/FontsFree-Net-Lettown-Hills.ttf") format("truetype");
	font-weight: 500;
}

/* Futura */
@font-face {
	font-family: "Futura";
	src: local("FuturaLight"), url("./assets/fonts/Futura/futura\ light\ bt.ttf") format("truetype");
	font-weight: 100;
}
@font-face {
	font-family: "Futura";
	src: local("FuturaMedium"), url("./assets/fonts/Futura/Futura\ Book\ font.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "Futura";
	src: local("FuturaSemiBold"),
		url("./assets/fonts/Futura/Futura\ Heavy\ font.ttf") format("truetype");
	font-weight: 600;
}
@font-face {
	font-family: "Futura";
	src: local("FuturaBold"), url("./assets/fonts/Futura/FuturaLTPro-Bold.otf") format("truetype");
	font-weight: 700;
}
@font-face {
	font-family: "Futura";
	src: local("FuturaBolder"), url("./assets/fonts/Futura/Futura\ XBlk\ BT.ttf") format("truetype");
	font-weight: 800;
}
@font-face {
	font-family: "Futura";
	src: local("FuturaBoldest"),
		url("./assets/fonts/Futura/Futura\ Extra\ Black\ font.ttf") format("truetype");
	font-weight: 900;
}

body {
	background-color: white;
	font-family: "Futura";
	/* font-family: "Hind"; */
}
